/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


        //Mobile menu
        var nav = $('#js-menu-trigger'),
            icon = $('#menu-icon'),
            mobileNav = $('#nav-header'),
            navItems = {1:nav, 2:icon, 3:mobileNav};

        nav.click(function(e) {
          e.preventDefault();
          if(!nav.hasClass('js-active')) {
            $.each(navItems, function(index, element){
              element.addClass('js-active');
            });
          }
          else {
            $.each(navItems, function(index, element){
              element.removeClass('js-active');
            });
          }
        });

        // Back to top
        var backToTop = $('.back-to-top');
        backToTop.click(function() {
          $('body,html').animate({scrollTop: 0}, 500);
          return false;
        });

        //Set height to the first recruit section
        $(window).on('load resize', function(){
          var blockHeight = $('.block-float').innerHeight(),
              blockTop = $('.block-float').css('top'),
              blockTopNumber = blockTop.replace(/[^0-9]/g, ''),
              blockFloatInnerHeight = blockHeight-blockTopNumber;
          $('.block-float-inner').height(blockFloatInnerHeight);

          var full = window.location.host,
              parts = full.split('.'),
              sub = parts[0];

          // if(sub=='si'||sub=='arch') {
            var serviceHeight = $('.service-float').innerHeight(),
                windowWidth = window.innerWidth;
            if(windowWidth < 768) {
              // Set service-float height to service-front-top-container
              $('.service-front-top-container').css('height' , serviceHeight+'px');
              var serviceFrontTopContainerHeight = $('.service-front-top-container').innerHeight(),
                  serviceFloatInnertHeight = serviceHeight - (serviceFrontTopContainerHeight - blockFloatInnerHeight - 60);
              $('.service-float-inner').height(serviceFloatInnertHeight);
              $('.service-float').css('top',blockFloatInnerHeight + 60+'px');
            }
            else {
              // Set service-float auto to service-front-top-container
              $('.service-front-top-container').css('height' , 'auto');
              var serviceFrontTopContainerHeight = $('.service-front-top-container').innerHeight(),
                  serviceFloatInnertHeight = serviceHeight - (serviceFrontTopContainerHeight - blockFloatInnerHeight - 100);
              $('.service-float-inner').height(serviceFloatInnertHeight);
              $('.service-float').css('top',blockFloatInnerHeight + 100+'px');
            }
          // }
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
